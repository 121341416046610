
import { Component, Vue } from 'vue-property-decorator'
import { IUserProfile } from '@/interfaces/users'
import userModule from '@/store/user'

@Component
export default class UsersListView extends Vue {
  public headers = [
    {
      text: 'Email',
      sortable: true,
      value: 'email',
      align: 'left'
    },
    {
      text: 'Full Name',
      sortable: true,
      value: 'full_name',
      align: 'left'
    },
    {
      text: 'Is Active',
      sortable: true,
      value: 'is_active',
      align: 'left'
    },
    {
      text: 'Role',
      value: 'role'
    },
    {
      text: 'Actions',
      value: 'id'
    }
  ]
  get users(): IUserProfile[] {
    return userModule.users
  }
  public getRoleName(user: IUserProfile) {
    if (user.is_superuser) {
      return 'System User'
    }
    return ''
  }
  public async mounted(): Promise<void> {
    await Promise.all([userModule.actionLoadUsers()])
  }
}
