import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules'
import { IUserProfile, IUserProfileUpdate, IUserProfileCreate } from '@/interfaces/users'
import api from '@/api'

import store from '@/store'
import mainModule from '@/store/main'

@Module
export class UserModule extends VuexModule {
  // state
  users: IUserProfile[] = []

  // getters
  get user() {
    return (userId: number) => {
      const filteredUsers = this.users.filter((user) => user.id === userId)
      if (filteredUsers.length > 0) {
        return { ...filteredUsers[0] }
      }
    }
  }

  // mutations
  @Mutation
  setUsers(payload: IUserProfile[]): void {
    this.users = payload
  }

  @Mutation
  setUser(payload: IUserProfile): void {
    const users = this.users.filter((user: IUserProfile) => user.id !== payload.id)
    users.push(payload)
    this.users = users
  }

  // actions
  @Action
  async actionLoadUsers(): Promise<void> {
    try {
      const response = await api.user.getUsers(mainModule.token)
      if (response) {
        this.setUsers(response.data)
      }
    } catch (error) {
      await mainModule.actionCheckApiError(error)
    }
  }

  @Action
  async actionUpdateUser(payload: { id: number; user: IUserProfileUpdate }): Promise<void> {
    try {
      const loadingNotification = {
        content: 'saving',
        showProgress: true
      }

      mainModule.addNotification(loadingNotification)
      const response = (
        await Promise.all([
          api.user.updateUser(mainModule.token, payload.id, payload.user),
          await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500))
        ])
      )[0]

      this.setUser(response.data)

      mainModule.removeNotification(loadingNotification)
      mainModule.addNotification({
        content: 'User successfully updated',
        color: 'success'
      })
    } catch (error) {
      await mainModule.actionCheckApiError(error)
    }
  }

  @Action
  async actionCreateUser(payload: IUserProfileCreate): Promise<void> {
    try {
      const loadingNotification = {
        content: 'saving',
        showProgress: true
      }
      mainModule.addNotification(loadingNotification)
      const response = (
        await Promise.all([
          api.user.createUser(mainModule.token, payload),
          await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500))
        ])
      )[0]
      this.setUser(response.data)
      mainModule.removeNotification(loadingNotification)
      mainModule.addNotification({
        content: 'User successfully created',
        color: 'success'
      })
    } catch (error) {
      await mainModule.actionCheckApiError(error)
    }
  }

  @Action
  async actionDeleteUser(payload: { id: number }): Promise<void> {
    try {
      const loadingNotification = {
        content: 'deleting',
        showProgress: true
      }
      mainModule.addNotification(loadingNotification)
      await api.user.deleteUser(mainModule.token, payload.id)

      this.setUsers(this.users.filter((user: IUserProfile) => user.id !== payload.id))
      mainModule.removeNotification(loadingNotification)
      mainModule.addNotification({
        content: 'User successfully deleted',
        color: 'success'
      })
    } catch (error) {
      await mainModule.actionCheckApiError(error)
    }
  }
}

export const userModule = new UserModule({ store, name: 'user' })
export default userModule
